.ant-btn-primary {
  background: #c1801e !important;
  border-color: #b46e03;
}

.ant-btn-primary:active {
  border-color: #c78d35 !important;
}

.ant-btn-primary:focus {
  border-color: #b46e03 !important;
}

.ant-btn-primary:hover {
  border-color: #c78d35 !important;
}

.ant-btn-primary:disabled {
  background-color: #c1801e !important;
  color: white !important;
  opacity: 0.8;
}

.ant-btn-dangerous {
  background: #ff4d4f !important;
  border-color: rgba(255, 77, 79, .2) !important;
  outline-color: rgba(255, 77, 79, .2) !important;
  outline-style: solid;
}

.ant-btn-dangerous::after {
  outline-color: rgba(255, 77, 79, .2) !important;
}

.ant-btn-dangerous:disabled {
  background: #ff4d4f !important;
  border-color: rgba(255, 77, 79, .2) !important;
  opacity: 0.8;
}

.ant-btn-dangerous:hover {
  background: #ff4d4f !important;
  border-color: rgba(255, 77, 79, .2) !important;
}

.ant-btn-dangerous:active {
  background: #d9363e !important;
  border-color: rgba(255, 77, 79, .2) !important;
}

.pie {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pie.spinner-border,
.pie:before {
  width: 18px;
  height: 18px;
}

.pie:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
  /* margin-left: auto;
  margin-right: auto; */
}

.pie.pie-no-access::before {
  border: 2px solid #cc0d0d;
  background-color: transparent !important;
  cursor: "pointer";
}

.pie.pie-own-record:before {
  background-color: #ffca00;
  border: 2px solid #ffca00;
  background-image: linear-gradient(52.5deg, transparent 50%, #fff 0), linear-gradient(127.5deg, #fff 50%, transparent 0);
}

.pie.pie-business-unit:before {
  background-color: #ffca00;
  border: 2px solid #ffca00;
  background-image: linear-gradient(180deg, #fff 50%, transparent 0);
}

.pie.pie-parent-child:before {
  /* ontent: ""; */
  display: inline-block;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(232.5deg, transparent 50%, green 0), linear-gradient(127.5deg, #fff 50%, transparent 0);
  /* background-image: linear-gradient(229deg, transparent 50%, #fff 0), linear-gradient(315deg, #fff 50%, transparent 0); */
  /* background-image: linear-gradient(139deg, transparent 50%, green 0), linear-gradient(223deg, #fff 50%, transparent 0); */
}



.my-grid>.dx-datagrid>.dx-datagrid-headers>.dx-datagrid-content>table>tbody>tr>td,
.my-grid>.dx-datagrid>.dx-datagrid-rowsview>.dx-datagrid-content>table>tbody>tr>td {
  text-align: center !important;
}

.my-grid>.dx-datagrid>.dx-datagrid-headers>.dx-datagrid-content>table>tbody>tr>td:nth-child(1),
.my-grid>.dx-datagrid>.dx-datagrid-rowsview>.dx-datagrid-content>table>tbody>tr>td:nth-child(1) {
  text-align: left !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none !important;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.form-control-xs {
  height: calc(2.2em + .375rem + 2px) !important;
  padding: .125rem .5rem !important;
  /* padding-top: calc(0.775rem + 1px); */
  font-size: .75rem !important;
  line-height: 1.5;
  border-radius: .2rem;
}

.ant-input.ant-input-sm
/* , */
/* .ant-picker.ant-picker-small>.ant-picker-input>input  */
  {
  font-size: .85rem !important;
}